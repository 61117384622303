import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {difference, filter, flatten, flow, isEmpty, map, negate} from 'lodash/fp';
import {match as Match} from 'react-router';
import {Opt, opt, optEmptyArray} from 'ts-opt';

import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {loadCustomerMilkRooms, resetCustomerMilkRooms} from 'user/actions';
import withUser, {WithUserProps} from 'user/components/withUser';
import {loadSampleDetails, loadSamplesByOrder, updateSample} from 'sample/actions';
import {Analysis} from 'types/model/analyses/Analysis';
import {Components as Layout} from 'layout';
import {OrderDetails} from 'types/model/orders/OrderDetails';
import {StoreState} from 'app/types/StoreState';
import {loadAnalyses} from 'analysis/actions';
import {SampleDetails} from 'types/model/samples/SampleDetails';
import {CreateSampleFormValues} from 'sample/types/CreateSampleFormValues';
import {formValuesF} from 'utils/formHelpers';
import {SampleDefaults} from 'sample/types/SampleDefaults';
import {CustomerDetailsForLaborer} from 'types/model/customers/CustomerDetailsForLaborer';
import {CustomerDetails} from 'types/model/customers/CustomerDetails';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';
import {SelectOptions} from 'forms/components/BasicSelect';
import {PriceClass} from 'types/model/enumeration/PriceClass';
import UpdateSampleForm from 'sample/components/UpdateSampleForm';
import {UpdateSampleFormValues} from 'sample/types/UpdateSampleFormValues';
import {loadMilkRooms} from 'supplier/actions';

import {
    loadOrder,
    loadCustomerForOrder,
    setAnalysesBySelectedDataItems,
    resetCustomerForOrder,
    prefillCreateSampleForm,
    updateSample as updateOrderSample,
} from '../actions';
import CreateSampleForm, {initialCreateSampleState} from '../components/CreateSampleForm';
import {prepareCreateSampleFormValues} from '../utils/prepareCreateSampleFormValues';
import {getMilkRoomsOptions, getAnalysesOptions} from '../utils/selectOptions';
import {
    getOrderCustomer,
    getPriceClassForCustomer,
    enableShowResultsByCustomer,
    enableQCZByCustomer,
    getOrderCustomerId,
} from '../utils/orderUtils';
import {getSampleDefaults} from '../utils/getSampleDefaults';

interface OuterProps {
}

interface StateProps {
    formValue?: UpdateSampleFormValues;
    sample: SampleDetails | null;
    analyses: Analysis[];
    order: Opt<OrderDetails>;
    orderSamples: Opt<OrderSampleView[]>;
    customerMilkRooms: MilkRoom[] | null;
    milkRooms: MilkRoom[];
    analysesBySelectedDataItems: Analysis[];
    currentCreateSample: CreateSampleFormValues | null;
    customerForOrder: CustomerDetailsForLaborer | null;
}

interface DispatchProps {
    handleLoadOrder(id: number): void;

    handleLoadSample(id: number): void;

    handleLoadAnalyses(): void;

    handleSetAnalysesBySelectedDataItems(analyses: Analysis[]): void;

    handleLoadMilkRooms(): void;

    handleLoadCustomerMilkRooms(customerId: number): void;

    handleResetCustomerMilkRooms(): void;

    handleLoadCustomerForOrder(customerId: number): void;

    handleResetCustomerForOrder(): void;

    handlePrefillCreateSampleForm(sample: SampleDetails): void;

    handleUpdateOrderSample(id: number): void;

    handleUpdateSample(id: number, orderId: number): void;

    handleLoadOrderSamples(id: number): void;
}

type InnerProps = StateProps & DispatchProps & {
    match: Match<{id: string, sampleId: string}>,
};


type UserProps = Pick<WithUserProps,
    'isRoleAdmin' | 'isRoleLaborer' | 'isRoleDairy' | 'isRoleDairyEmployee' | 'currentCustomer'
    >;
type Props = InnerProps & OuterProps & UserProps;

class EditExistingSample extends Component<Props> {
    private get orderId(): number {
        const {match} = this.props;
        return Number(match.params.id);
    }

    private get sampleId(): number {
        const {match} = this.props;
        return Number(match.params.sampleId);
    }

    private get orderCustomer(): Opt<CustomerDetails> {
        const {currentCustomer, customerForOrder} = this.props;

        return getOrderCustomer(currentCustomer, opt(customerForOrder), this.isAdminOrLaborer);
    }

    private get priceClass(): PriceClass | undefined {
        return getPriceClassForCustomer(this.orderCustomer);
    }

    private get enableShowResults(): boolean {
        return enableShowResultsByCustomer(this.orderCustomer);
    }

    private get isDairy(): boolean {
        const {isRoleDairy, isRoleDairyEmployee} = this.props;

        return isRoleDairy || isRoleDairyEmployee;
    }

    private get isAdminOrLaborer(): boolean {
        const {isRoleAdmin, isRoleLaborer} = this.props;

        return isRoleAdmin || isRoleLaborer;
    }

    private get showQCZ(): boolean {
        return enableQCZByCustomer(this.orderCustomer);
    }

    private get sampleDefaults(): SampleDefaults | undefined {
        return getSampleDefaults(this.orderCustomer, this.isDairy);
    }

    private get showTraits(): boolean {
        return this.isAdminOrLaborer || this.isDairy;
    }

    componentDidMount(): void {
        const {
            handleLoadOrder,
            handleLoadSample,
            handleLoadAnalyses,
            handleLoadOrderSamples,
        } = this.props;

        handleLoadAnalyses();
        handleLoadOrder(this.orderId);
        handleLoadOrderSamples(this.orderId);
        handleLoadSample(this.sampleId);
    }

    componentDidUpdate(prevProps: Readonly<InnerProps & OuterProps>): void {
        const {
            order,
            handleLoadMilkRooms,
            handleLoadCustomerForOrder,
            handleResetCustomerForOrder,
            handlePrefillCreateSampleForm,
            sample,
        } = this.props;
        if (!order.isEmpty && order.orUndef() !== prevProps.order.orUndef()) {
            const customerId = getOrderCustomerId(order);

            if (this.isAdminOrLaborer) {
                this.loadMilkRoomsByCustomer(customerId);
            } else {
                handleLoadMilkRooms();
            }

            if (customerId) {
                handleLoadCustomerForOrder(customerId);
            } else {
                handleResetCustomerForOrder();
            }
        }

        if (sample && sample !== prevProps.sample) {
            handlePrefillCreateSampleForm(sample);
        }
    }

    componentWillUnmount(): void {
        const {handleResetCustomerMilkRooms} = this.props;
        handleResetCustomerMilkRooms();
    }

    render(): ReactNode {
        const {
            order,
        } = this.props;

        if (order.isEmpty) { return null; }

        return (
            <Layout.ItemPage
                title="Úprava vzorku"
                backLabel="Objednávka"
            >
                {this.renderForm()}
            </Layout.ItemPage>
        );
    }

    private renderUpdateSampleForm = (): ReactNode => {
        const {
            sample,
            handleUpdateSample,
            formValue,
        } = this.props;

        return (
            <UpdateSampleForm
                mode="EDIT"
                enableShowResults={this.enableShowResults}
                onSubmit={() => handleUpdateSample(this.sampleId, this.orderId)}
                sample={sample}
                analysesOptions={this.getAnalysesOptions()}
                formValues={formValue}
                allowQCZ={this.showQCZ}
                milkRoomsOptions={this.isAdminOrLaborer ? this.getMilkRoomsOptions() : undefined}
            />
        );
    };

    private renderCreateSampleForm = (): ReactNode => {
        const {
            handleUpdateOrderSample,
            currentCreateSample,
        } = this.props;

        return (
            <CreateSampleForm
                onSubmit={() => handleUpdateOrderSample(this.sampleId)}
                inOrderCreation={false}
                analysesOptions={this.getAnalysesOptions()}
                milkRoomsOptions={this.getMilkRoomsOptions()}
                sampleBarcodeIds={this.getBarcodes()}
                handleLoadAnalysesOptions={this.handleLoadAnalysesOptions}
                initialValues={{...initialCreateSampleState, ...this.sampleDefaults}}
                enableShowResults={this.enableShowResults}
                showTraits={this.showTraits}
                formValues={currentCreateSample ?? undefined}
                allowQCZ={this.showQCZ}
            />
        );
    };

    private renderForm(): ReactNode {
        const {
            order,
        } = this.props;

        if (order.isEmpty) {
            return null;
        }

        return this.isAdminOrLaborer ? this.renderUpdateSampleForm() : this.renderCreateSampleForm();
    }

    private getMilkRoomsOptions(): SelectOptions<number> | undefined {
        const {milkRooms, customerMilkRooms, sample} = this.props;

        return this.isAdminOrLaborer
            ? getMilkRoomsOptions(customerMilkRooms, sample?.milkRoomId)
            : getMilkRoomsOptions(milkRooms, sample?.milkRoomId);
    }

    private getAnalysesOptions(): SelectOptions<number> {
        const {analyses, analysesBySelectedDataItems} = this.props;
        const filteredAnalyses = optEmptyArray(analysesBySelectedDataItems)
            .orElse(analyses);

        return getAnalysesOptions(filteredAnalyses, this.priceClass);
    }

    private readonly handleLoadAnalysesOptions = (ids: number[]): void => {
        const {
            handleSetAnalysesBySelectedDataItems,
            analyses,
        } = this.props;

        if (isEmpty(ids)) {
            handleSetAnalysesBySelectedDataItems([]);
        } else {
            const dataItemIdsBySelectedAnalyses: number[][] = flow(
                map(id => filter(a => a.id === id, analyses)),
                flatten,
                map(x => x.dataItemIds),
            )(ids);

            const includeSameDataItemIds = (analysisIds: number[]): boolean => flow(
                map((x: number[]): number[] | false => analysisIds !== x && difference(analysisIds, x)),
                filter((x: number[] | false): boolean => x && isEmpty(x)),
                negate(isEmpty),
            )(dataItemIdsBySelectedAnalyses);

            const filteredAnalyses: Analysis[] =
                filter(a => !includeSameDataItemIds(a.dataItemIds), analyses);
            handleSetAnalysesBySelectedDataItems(filteredAnalyses);
        }
    };

    private getBarcodes(): string[] {
        const {orderSamples, sample} = this.props;
        const barcodes = orderSamples.orElse([])
            .map(x => x.barcode);

        return sample ? barcodes.filter(x => x !== sample.barcode) : barcodes;
    }

    private loadMilkRoomsByCustomer(customerId: number | null): void {
        const {handleLoadCustomerMilkRooms, customerMilkRooms} = this.props;

        if (customerId && !customerMilkRooms) {
            handleLoadCustomerMilkRooms(customerId);
        }
    }
}

const mapStateToProps = (state: StoreState): StateProps => ({
    formValue: formValuesF('updateSample')(state).orUndef(),
    sample: state.sample.sampleDetails,
    order: opt(state.order.current),
    orderSamples: opt(state.sample.orderSamples),
    milkRooms: state.supplier.milkRooms || [],
    analyses: state.analysis.analyses || [],
    analysesBySelectedDataItems: state.order.analysesBySelectedDataItems || [],
    customerMilkRooms: state.user.customerMilkRooms.orNull(),
    currentCreateSample: formValuesF('createSample')(state).orNull(),
    customerForOrder: state.order.customer,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadSample: (id: number) => dispatch(loadSampleDetails(id)),
    handleLoadOrderSamples: (id: number) => dispatch(loadSamplesByOrder(id)),
    handleLoadOrder: (id: number) => dispatch(loadOrder(id)),
    handleLoadAnalyses: () => dispatch(loadAnalyses()),
    handleSetAnalysesBySelectedDataItems: (analyses: Analysis[]) =>
        dispatch(setAnalysesBySelectedDataItems(analyses)),
    handleLoadMilkRooms: () => dispatch(loadMilkRooms()),
    handleLoadCustomerMilkRooms: (id: number) => dispatch(loadCustomerMilkRooms(id)),
    handleResetCustomerMilkRooms: () => dispatch(resetCustomerMilkRooms()),
    handleLoadCustomerForOrder: (customerId: number) => dispatch(loadCustomerForOrder(customerId)),
    handleResetCustomerForOrder: () => dispatch(resetCustomerForOrder()),
    handleUpdateSample: (id: number, orderId: number) => dispatch(updateSample(id, `/orders/${orderId}`)),
    handleUpdateOrderSample: (id: number) => dispatch(updateOrderSample(id)),
    handlePrefillCreateSampleForm: (sample: SampleDetails) =>
        dispatch(prefillCreateSampleForm(prepareCreateSampleFormValues(sample))),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(EditExistingSample) as ComponentClass<OuterProps>;
