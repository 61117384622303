import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';

import {StoreState} from 'app/types/StoreState';
import {Components as Layout} from 'layout';
import {Analysis} from 'types/model/analyses/Analysis';
import {SampleDetails} from 'types/model/samples/SampleDetails';
import withUser from 'user/components/withUser';
import {loadAnalyses} from 'analysis/actions';
import {formValuesF} from 'utils/formHelpers';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {loadCustomerMilkRooms, resetCustomerMilkRooms} from 'user/actions';
import {getMilkRoomsOptions, getAnalysesOptions} from 'order/utils/selectOptions';
import {enableQCZBySample, enableShowResultsBySample} from 'order/utils/orderUtils';

import {loadSampleDetails, updateSample} from '../actions';
import {UpdateSampleFormValues} from '../types/UpdateSampleFormValues';
import UpdateSampleForm from '../components/UpdateSampleForm';

interface OuterProps {}

interface StateProps {
    sample: SampleDetails | null;
    analyses: Analysis[];
    formValues?: UpdateSampleFormValues;
    milkRooms: MilkRoom[] | null;
}

interface DispatchProps {
    handleLoadSample(id: number): void;
    handleUpdateSample(id: number): void;
    handleLoadAnalyses(): void;
    handleLoadMilkRooms(customerId: number): void;
    handleResetMilkRooms(): void;
}

type Props = OuterProps & StateProps & DispatchProps & RouteComponentProps<{id: string}>;

class Detail extends Component<Props> {
    private get sampleId(): number {
        const {match} = this.props;

        return Number(match.params.id);
    }

    componentDidMount(): void {
        const {handleLoadAnalyses, handleLoadSample, handleResetMilkRooms} = this.props;

        handleResetMilkRooms();
        handleLoadSample(this.sampleId);
        handleLoadAnalyses();
    }

    componentDidUpdate(prevProps: Props): void {
        const {handleLoadMilkRooms, sample} = this.props;
        const {sample: prevSample} = prevProps;

        if (prevSample !== sample && sample) {
            handleLoadMilkRooms(sample.customerId);
        }
    }

    render(): ReactNode {
        const {
            sample,
            formValues,
            analyses,
            milkRooms,
        } = this.props;

        return (
            <Layout.ItemPage
                title="Úprava vzorku"
                backLabel="Vzorky"
            >
                <UpdateSampleForm
                    mode="EDIT"
                    enableShowResults={enableShowResultsBySample(sample)}
                    onSubmit={this.handleSubmit}
                    sample={sample}
                    analysesOptions={getAnalysesOptions(analyses, sample?.priceClass)}
                    formValues={formValues}
                    allowQCZ={enableQCZBySample(sample)}
                    milkRoomsOptions={getMilkRoomsOptions(milkRooms, sample?.milkRoomId)}
                />
            </Layout.ItemPage>
        );
    }

    private handleSubmit = () => {
        const {handleUpdateSample} = this.props;

        handleUpdateSample(this.sampleId);
    };
}

const mapStateToProps = (state: StoreState): StateProps => ({
    sample: state.sample.sampleDetails,
    analyses: state.analysis.analyses || [],
    formValues: formValuesF('updateSample')(state).orUndef(),
    milkRooms: state.user.customerMilkRooms.orNull(),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleLoadSample: (id: number) => dispatch(loadSampleDetails(id)),
    handleLoadAnalyses: () => dispatch(loadAnalyses()),
    handleUpdateSample: (id: number) => dispatch(updateSample(id)),
    handleLoadMilkRooms: (customerId: number) => dispatch(loadCustomerMilkRooms(customerId)),
    handleResetMilkRooms: () => dispatch(resetCustomerMilkRooms()),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Detail) as ComponentClass<OuterProps>;
