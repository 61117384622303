import React, {Component, ComponentClass, ReactNode, Fragment} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {flow, isEmpty} from 'lodash/fp';
import {RouteComponentProps, StaticContext} from 'react-router';
import {opt} from 'ts-opt';
import {showError} from 'favorlogic-utils';

import UpdateSampleForm from 'sample/components/UpdateSampleForm';
import withUser, {WithUserProps} from 'user/components/withUser';
import {
    acceptOrder,
    loadOrder,
    resetCurrentSample,
    resetOrder,
    setCurrentSample,
    updateSampleGloballyFormValues,
} from 'order/actions';
import {acceptSample, loadSamplesByOrder, prefillUpdateSampleForm, resetSamplesByOrder} from 'sample/actions';
import {Analysis} from 'types/model/analyses/Analysis';
import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import {formValuesF, isValidF} from 'utils/formHelpers';
import {StoreState} from 'app/types/StoreState';
import {UpdateSampleFormValues} from 'sample/types/UpdateSampleFormValues';
import {UpdateSampleGloballyFormValues} from 'order/types/UpdateSampleGloballyFormValues';
import {loadAnalyses} from 'analysis/actions';
import {getMilkRoomsOptions, getAnalysesOptions} from '../utils/selectOptions';
import {loadCustomerMilkRooms} from 'user/actions';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {BarcodeSearchFormValues} from 'order/types/BarcodeSearchFormValues';
import {OrderDetails} from 'types/model/orders/OrderDetails';
import {OrderSampleView} from 'types/model/samples/OrderSampleView';
import {Message} from 'layout/components';
import {
    enableShowResultsByOrder,
    isOrderDetailsForLaborer,
    enableQCZByOrder,
    getPriceClassForOrder,
} from '../utils/orderUtils';
import {SampleButtons} from '../components/SampleButtons';
import BarcodeSearchForm from '../components/BarcodeSearchForm';

interface OuterProps {}

interface InnerProps {
    analyses: Analysis[];
    order: OrderDetails | null;
    sample: OrderSampleView | null;
    orderSamples: OrderSampleView[] | null;
    formValue?: UpdateSampleFormValues;
    barcodeSearchFormValues?: BarcodeSearchFormValues;
    isUpdateSampleFormValid: boolean;
    isSubmitting: boolean;
    globallyFormValues: UpdateSampleGloballyFormValues | null;
    milkRooms: MilkRoom[] | null;
    isLoading: boolean;

    handleResetOrder(): void;
    handleLoadOrder(id: number): void;
    handleAcceptSample(id: number, isLast: boolean): void;
    handleLoadAnalyses(): void;
    handleUpdateSampleGlobally(values: UpdateSampleGloballyFormValues | null): void;
    loadMilkRooms(customerId: number): void;
    handleShowError(title: string, message: string): void;
    prefillUpdateSampleForm(values: UpdateSampleFormValues): void;
    handleSetCurrentSample(sample: OrderSampleView): void;
    handleResetCurrentSample(): void;
    handleLoadSamplesByOrder(orderId: number): void;
    handleAcceptOrder(orderId: number): void;
    resetSamplesByOrder(): void;
}

type RouterProps = Pick<RouteComponentProps<{id: string}, StaticContext, {sampleId?: number}>, 'match' | 'location'>;
type UserProps = Pick<WithUserProps,
    'isRoleAdmin' | 'isRoleLaborer' | 'isRoleDairy' | 'isRoleDairyEmployee' | 'currentUser'
    >;

type Props = InnerProps & OuterProps & RouterProps & UserProps;

class AcceptSamples extends Component<Props> {
    private get orderId(): number {
        const {match} = this.props;
        return Number(match.params.id);
    }

    private get customerName(): string | undefined {
        const {order} = this.props;
        return order?.customerDetails.customerName;
    }

    componentDidMount(): void {
        const {
            handleResetOrder,
            handleLoadOrder,
            handleLoadSamplesByOrder,
            handleLoadAnalyses,
            resetSamplesByOrder,
            analyses,
        } = this.props;
        handleResetOrder();
        resetSamplesByOrder();
        if (isEmpty(analyses)) { handleLoadAnalyses(); }
        handleLoadOrder(this.orderId);
        handleLoadSamplesByOrder(this.orderId);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const {
            order,
            orderSamples,
            milkRooms,
            location,
        } = this.props;

        if (prevProps.orderSamples === null && orderSamples !== null) {
            this.selectSampleRequestedInRedirect(location.state?.sampleId);
        }

        if (prevProps.order !== order && isEmpty(milkRooms)) {
            this.loadMilkRooms();
        }
    }

    componentWillUnmount(): void {
        const {handleResetCurrentSample, handleUpdateSampleGlobally} = this.props;
        handleUpdateSampleGlobally(null);
        handleResetCurrentSample();
    }

    render(): ReactNode {
        const {orderSamples} = this.props;

        return (
            <Layout.ItemPage
                title={this.renderTitle()}
                backLabel="Objednávka"
            >
                {orderSamples &&
                    (
                        this.isEverythingAccepted()
                            ? this.renderEverythingAccepted()
                            : this.renderWizard()
                    )
                }
            </Layout.ItemPage>
        );
    }

    private renderTitle(): ReactNode {
        return (
            <Fragment>
                Příjem vzorků - Objednávka #{this.orderId} <br /> {this.customerName}
            </Fragment>
        );
    }

    private renderEverythingAccepted(): ReactNode {
        return (
            <Message>
                <div className="d-flex flex-column align-items-center">
                    <div className="h5">
                        Všechny vzorky v objednávce již byly přijaty.
                    </div>
                    <div>
                        {this.renderAcceptOrderButton()}
                    </div>
                </div>
            </Message>
        );
    }

    private readonly selectSampleRequestedInRedirect = (sampleId: number | undefined) => {
        const {orderSamples, handleShowError} = this.props;
        if (orderSamples !== null && sampleId !== undefined) {
            const sample = orderSamples.find(s => s.sampleId === sampleId);
            if (!sample) {
                handleShowError('Hledání vzorku', `Vzorek s ID ${sampleId} nebyl nalezen`);
                return;
            }
            if (sample.accepted) {
                handleShowError('Hledání vzorku', `Vzorek s čárovým kódem ${sample.barcode} už byl přijat`);
                return;
            }

            this.prefill(sample);
        }
    }

    private renderWizard = (): ReactNode => {
        const {
            order,
            sample,
            formValue,
            globallyFormValues,
            analyses,
            milkRooms,
            barcodeSearchFormValues,
            isRoleAdmin,
            isRoleDairy,
            isRoleDairyEmployee,
            isRoleLaborer,
        } = this.props;

        if (!order) {
            return null;
        }

        const showDairyTraits = isRoleAdmin || isRoleLaborer || isRoleDairy || isRoleDairyEmployee;

        return (
            <Fragment>
                {!sample &&
                    <Fragment>
                        <SampleButtons
                            samples={this.getNotAcceptedSamples()}
                            onClickSample={this.handleSelectSample}
                        />
                        <BarcodeSearchForm
                            onChangeBarcode={this.handleSelectSample}
                            formValues={barcodeSearchFormValues}
                        />
                    </Fragment>
                }

                {sample &&
                    <Fragment>
                        <UpdateSampleForm
                            enableShowResults={enableShowResultsByOrder(order)}
                            sample={sample}
                            mode="ADD"
                            analysesOptions={getAnalysesOptions(analyses, getPriceClassForOrder(order))}
                            setGlobally={this.handleSetGlobally}
                            cancelSetGlobally={this.handleCancelSetGlobally}
                            hasGloballyValues={!isEmpty(globallyFormValues)}
                            milkRoomsOptions={getMilkRoomsOptions(milkRooms, sample.milkRoomId)}
                            initialValues={formValue}
                            formValues={formValue}
                            showDairyTraits={showDairyTraits}
                            allowQCZ={enableQCZByOrder(order)}
                        />
                        <div className="w-100 text-right">
                            {this.renderAcceptSampleButton()}
                        </div>
                    </Fragment>
                }
            </Fragment>
        );
    };

    private getNotAcceptedSamples = (): OrderSampleView[] => {
        const {orderSamples} = this.props;

        if (!orderSamples) {
            return [];
        }

        return orderSamples.filter(s => !s.accepted);
    };

    private renderAcceptSampleButton = (): ReactNode => {
        const {isSubmitting, isUpdateSampleFormValid} = this.props;

        return (
            <Buttons.Button
                type="button"
                importance="primary"
                onClick={this.handleAcceptSample}
                disabled={isSubmitting || !isUpdateSampleFormValid}
            >
                {this.isLastSampleForAccept() ? 'Příjmout objednávku' : 'Příjmout vzorek'}
            </Buttons.Button>
        );
    };

    private renderAcceptOrderButton = (): ReactNode =>
        <Buttons.Button
            type="button"
            importance="primary"
            onClick={this.handleAcceptOrder}
        >
            Příjmout objednávku
        </Buttons.Button>
    ;

    private handleAcceptSample = () => {
        const {sample, handleAcceptSample} = this.props;

        if (!sample) {
            return;
        }

        handleAcceptSample(sample.sampleId, this.isLastSampleForAccept());
    };

    private handleAcceptOrder = () => {
        const {order, handleAcceptOrder} = this.props;

        if (!order) {
            return;
        }

        handleAcceptOrder(order.id);
    };

    private isLastSampleForAccept = () => this.getNotAcceptedSamples().length === 1;

    private isEverythingAccepted = () => isEmpty(this.getNotAcceptedSamples());

    private getUpdateFormPrefill = (sample: OrderSampleView): UpdateSampleFormValues => ({
        analysisIds: sample.analysisIds,
        barcode: sample.barcode,
        sampleSourceAmount: sample.sampleSourceAmount === undefined ? '' : String(sample.sampleSourceAmount),
        samplingTemperature: sample.samplingTemperature === undefined ? null : String(sample.samplingTemperature),
        samplingTime: sample.samplingTime ?? '',
        samplingDate: sample.samplingDate ?? '',
        type: sample.type,
        harvestType: sample.harvestType,
        milkRoomId: sample.milkRoomId ?? null,
        supplyChainCode: sample.supplyChainCode ?? null,
        deliveryTemperature: '',
        qualitative: sample.qualitative,
        cistern: sample.cistern,
        control: sample.control,
        subsidy: sample.subsidy,
        canceled: false,
        disabled: sample.disabled,
        note: '',
        preservatives: [],
        showResultsOnWeb: sample.showResultsOnWeb,
        showResultsToBreeders: sample.showResultsToBreeders,
        customerNote: sample.customerNote ?? null,
        traitNote: sample.traitNote ?? null,
    });

    private readonly handleSelectSample = (barcode: string): void => {
        const {orderSamples, handleShowError} = this.props;

        if (!barcode || !orderSamples) {
            return;
        }

        const sample = orderSamples.find(s => s.barcode === barcode);
        if (!sample) {
            handleShowError('Hledání vzorku', `Vzorek s čárovým kódem ${barcode} nebyl nalezen`);
            return;
        }
        if (sample.accepted) {
            handleShowError('Hledání vzorku', `Vzorek s čárovým kódem ${barcode} už byl přijat`);
            return;
        }

        this.prefill(sample);
    }

    private readonly prefill = (sample: OrderSampleView): void => {
        const {handleSetCurrentSample, globallyFormValues, prefillUpdateSampleForm} = this.props;

        const updateFormPrefillObj = this.getUpdateFormPrefill(sample);
        const prefillObj: UpdateSampleFormValues = opt(globallyFormValues).caseOf(
            (x) => ({...updateFormPrefillObj, ...x}),
            () => ({...updateFormPrefillObj})
        );

        handleSetCurrentSample(sample);
        prefillUpdateSampleForm(prefillObj);
    };

    private loadMilkRooms(): void {
        const {order, loadMilkRooms} = this.props;

        if (order && isOrderDetailsForLaborer(order)) {
            loadMilkRooms(order.customerDetails.id);
        }
    }

    private readonly handleSetGlobally = (): void => {
        const {handleUpdateSampleGlobally, formValue} = this.props;
        const values = opt(formValue).orCrash('missing form values');

        handleUpdateSampleGlobally({
            deliveryTemperature: values.deliveryTemperature,
            qualitative: values.qualitative,
            cistern: values.cistern,
            control: values.control,
            canceled: values.canceled,
            preservatives: values.preservatives,
        });
    };

    private readonly handleCancelSetGlobally = (): void => {
        this.props.handleUpdateSampleGlobally(null);
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    order: state.order.current,
    orderSamples: state.sample.orderSamples,
    sample: state.order.currentSample,
    analyses: state.analysis.analyses || [],
    formValue: formValuesF('updateSample')(state).orUndef(),
    barcodeSearchFormValues: formValuesF('barcodeSearchForm')(state).orUndef(),
    isUpdateSampleFormValid: isValidF('updateSample')(state),
    isSubmitting: state.layout.isLoading,
    globallyFormValues: state.order.updateSampleGloballyFormValues,
    milkRooms: state.user.customerMilkRooms.orUndef(),
    isLoading: state.layout.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleShowError: (title: string, message: string) => dispatch(showError(title, message)),
    handleSetCurrentSample: (sample: OrderSampleView) => dispatch(setCurrentSample(sample)),
    handleResetCurrentSample: () => dispatch(resetCurrentSample()),
    prefillUpdateSampleForm: (values: UpdateSampleFormValues) => dispatch(prefillUpdateSampleForm(values)),
    handleResetOrder: () => dispatch(resetOrder()),
    handleLoadOrder: (id: number) => dispatch(loadOrder(id)),
    handleLoadSamplesByOrder: (orderId: number) =>
        dispatch(loadSamplesByOrder(orderId)),
    resetSamplesByOrder: () => dispatch(resetSamplesByOrder()),
    handleAcceptSample: (id: number, isLast: boolean) => dispatch(acceptSample(id, isLast)),
    handleLoadAnalyses: () => dispatch(loadAnalyses()),
    handleUpdateSampleGlobally: (values: UpdateSampleGloballyFormValues | null) =>
        dispatch(updateSampleGloballyFormValues(values)),
    loadMilkRooms: (customerId: number) => dispatch(loadCustomerMilkRooms(customerId)),
    handleAcceptOrder: (orderId: number) => dispatch(acceptOrder(orderId)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(AcceptSamples) as ComponentClass<OuterProps>;
